import React, { useState } from 'react'
import { Link } from 'gatsby'

import Logo from './Logo'

import '../../static/styles/navbar.css'

export default () => {
  const [resourcesDropdownExpanded, setResourcesDropdownExpanded] = useState(
    false
  )

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-white"
      style={{ minHeight: '78px', paddingTop: '50px', paddingBottom: '20px' }}
    >
      <div className="container">
        <Link
          title="QuickMail"
          className="navbar-brand d-flex align-items-center"
          to="/"
        >
          <Logo />
          <div className="brand-name">QuickMail</div>
        </Link>
      </div>
    </nav>
  )
}
