import React from 'react'

export default () => (
  <svg
    width={44}
    height={34}
    viewBox="0 0 44 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="QuickMail-Logo">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8174 0.5H2H0V2.5V29.0479L4 25.0479V7.7794L12.002 16.8251L14.998 14.1749L6.43947 4.5H28.8174L32.8174 0.5Z"
        fill="black"
      />
      <g id="Vector">
        <path
          d="M15.5 15.5L1 32.5L15.5 20.5L21 25.5L35.5 10.5L37.5 12.5L43.5 0L30.5 5.5L32.5 7.5L21 20L15.5 15.5Z"
          fill="#545ff5"
        />
        <path d="M15.5 23.5L4 33.5H20V27.5L15.5 23.5Z" fill="#545ff5" />
        <path d="M31.5 19.5L22.5 27.5V33.5H31.5V19.5Z" fill="#545ff5" />
        <path d="M43.5 33.5H34.5V17.5L43.5 8.5V33.5Z" fill="#545ff5" />
      </g>
    </g>
  </svg>
)
