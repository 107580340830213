import React from 'react'
import { useStoryblokState } from "gatsby-source-storyblok";
import { Link } from 'gatsby'
import Image from "utils/Image"
import '../../static/styles/footer.css'

export default ({ posts, diagonalBefore }) => {
  const renderPost = (post) => {
    post = useStoryblokState(post)

    return (
      <div key={post.slug} className="latest-blog-post d-grid">
        <Link
          title={post.name}
          to={`/${post.slug}`}
          className="link d-block my-auto"
        >
          <div style={{ maxHeight: '83px', overflow: 'hidden' }}>
            {post.content.hero && (
              <div className="image-wrapper post-hero">
                <Image img={post.content.hero} alt={post.name} style={{ maxWidth: '100%' }} sizes="(max-width: 770px) 100px, 40vw" />
              </div>
            )}
          </div>
        </Link>
        <div className="d-flex flex-column justify-content-center">
          <Link
            title={post.name}
            to={`/${post.slug}`}
            className="link post-title"
          >
            {post.name}
          </Link>
          <small>
            {post.content.published_date
              ? new Date(post.content.published_date).toLocaleString(
                'en-US',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
              )
              : post.published_at}
          </small>
        </div>
      </div>
    )
  }

  // const footerClass = diagonalBefore ? 'diagonal-group white-diagonal-before dark-blue-bg' : 'dark-blue-bg'
  const footerClass = 'diagonal-group white-diagonal-before dark-blue-bg'

  return (
    <footer className={footerClass} id="footer">
      <div className="container content dark-blue-bg">
        <div className="row">
          <div className="col-12 brand-group">
            <div>
              <a className="brand" href="/">
                QuickMail
              </a>
            </div>
            <small className="text-muted">
              © Copyright 2014-{new Date().getFullYear()}. All Rights Reserved.
            </small>
          </div>
          {[
            {
              title: 'Features',
              links: [
                // {
                //   name: 'Overview',
                //   path: 'product',
                // },
                // {
                //   name: 'Auto-Warmer',
                //   path: 'auto-warmer',
                // },
                {
                  name: 'Inbox Rotation',
                  path: 'inbox-rotation',
                },
                // {
                //   name: 'Inbox Insight',
                //   path: 'deliverability-testing',
                // },
                // {
                //   name: 'Personalization',
                //   path: 'personalization',
                // },
                // {
                //   name: 'Dynamic Text',
                //   path: 'dynamic-text',
                // },
                // {
                //   name: 'A/B Testing',
                //   path: 'ab-testing',
                // },
                {
                  name: 'Integrations',
                  path: 'integrations',
                },
              ],
            },
            {
              title: 'Pricing & Use Cases',
              links: [
                {
                  name: 'Pricing',
                  path: 'pricing',
                },
                // {
                //   name: 'For Digital Agencies',
                //   path: 'agencies',
                // },
                // {
                //   name: 'For Business Development',
                //   path: 'business-development',
                // },
                // {
                //   name: 'For Startups & Entrepreneurs',
                //   path: 'startup',
                // },
                // {
                //   name: 'For SaaS',
                //   path: 'saas',
                // },
                // {
                //   name: 'For HR & Recruiting',
                //   path: 'recruiters',
                // },
                // {
                //   name: 'For PR & LinkBuilding',
                //   path: 'link-building',
                // },
                // {
                //   name: 'For Inbound Leads',
                //   path: 'inbound',
                // },
                {
                  name: 'Reviews 🔗',
                  url: 'https://www.g2.com/products/quickmail/reviews',
                },
              ],
            },
            {
              title: 'Tips & Resources',
              links: [
                {
                  name: 'Community 🔗',
                  url: 'https://www.facebook.com/groups/quickmail',
                },
                {
                  name: 'Courses',
                  path: 'cold-email-mastery-courses',
                },
                {
                  name: 'Podcast',
                  path: 'podcast',
                },
                {
                  name: 'Book 🔗',
                  url: 'http://amzn.to/1LfEVK2',
                },
                {
                  name: 'Ultimate Guide To Cold Email',
                  path: 'cold-email',
                },
                {
                  name: 'Call To Action Cheat Sheet',
                  path: 'cold-email/cta',
                },
                {
                  name: 'Cold Email Checklist',
                  path: 'checklist',
                },
              ],
            },
            {
              title: 'Get to Know Us',
              links: [
                // {
                //   name: 'About Us',
                //   path: 'about',
                // },
                {
                  name: 'Join Our Team',
                  path: 'jobs',
                },
                {
                  name: 'Terms & Conditions',
                  path: 'terms',
                },
                {
                  name: 'Privacy Policy',
                  path: 'privacy-policy',
                },
                {
                  name: 'GDPR',
                  path: 'dpa',
                },
              ],
            },
          ].map((column) => (
            <div key={column.title} className="col-6 col-sm-6 col-md-4 col-lg-2">
              <h2 className="section-heading">{column.title}</h2>
              <ul>
                {column.links.map((link) => (
                  <li key={link.name}>
                    {link.url ? (
                      <a
                        title={link.name}
                        href={link.url}
                        target="_blank"
                        className="link"
                        rel="noreferrer"
                      >
                        {link.name}
                      </a>
                    ) : (
                      <Link
                        title={link.name}
                        to={`/${link.path}`}
                        className="link"
                      >
                        {link.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="col-12 ml-auto mr-0 col-md-8 col-lg-4">
            <h2 className="section-heading">Hot Off the Press</h2>
            {posts.map((post) => renderPost(post))}
          </div>
        </div>
      </div>
    </footer>
  )
}