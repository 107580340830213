export default function Image({ img, eager, sizes, style, className, fullwidth, square, heightRatio, defaultLoading }) {
    const file = img?.filename || img?.src || null;

    const imageWidth = file?.split('/')[5]?.split('x')[0] || null;

    function Height(i) {
        if (square) { return i}
        else if (!heightRatio) { return 0 }
        else return Math.round(heightRatio * i)
    }
    
    if (img && file && (file?.endsWith('.svg') || file?.endsWith('.gif'))) {
        return (
            <img
                style={style}
                className={className}
                src={file}
                width={file?.split('/')[5]?.split('x')[0] !== '0' ? file?.split('/')[5]?.split('x')[0] : '250'}
                height={square ? file?.split('/')[5]?.split('x')[0] : file?.split('/')[5]?.split('x')[1] !== '0' ? file?.split('/')[5]?.split('x')[1] : '250'}
                alt={img?.name || img?.alt || ''}
                loading={eager ? 'eager' : defaultLoading ? null : img?.source === 'eager' ? 'eager' : 'lazy'}
                fetchpriority={eager ? 'high' : null}
            />
        )

    } else if (img && file && file?.endsWith('.mp4')) {
        return (
            <video style={style} className={className} autoPlay loop muted playsInline>
                <source src={file} type="video/mp4" />
            </video>
        )

    } else if (img && file && file?.endsWith('.webm')) {
        return (
            <video style={style} className={className} autoPlay loop muted playsInline>
                <source src={file} type="video/webm" />
            </video>
        )

    } else if (img && file) {
        return (
            <img
                style={style}
                className={className}
                srcSet={`
                    ${fullwidth === true ? `
                        ${file + `/m/2500x${Height(2500)}`} 2500w,
                        ${file + `/m/2000x${Height(2000)}`} 2000w,
                        ${file + `/m/1800x${Height(1600)}`} 1800w,
                    ` : ''}
                    ${imageWidth >= 1600 ? `${file + `/m/1600x${Height(1600)}/filters:quality(90)`} 1600w,` : ''}
                    ${imageWidth >= 1200 ? `${file + `/m/1200x${Height(1200)}/filters:quality(90)`} 1200w,` : ''}
                    ${imageWidth >= 1000 ? `${file + `/m/1000x${Height(1000)}/filters:quality(90)`} 1000w,` : ''}
                    ${imageWidth >= 800 ? `${file + `/m/800x${Height(800)}/filters:quality(90)`} 800w,` : ''}
                    ${imageWidth >= 700 ? `${file + `/m/700x${Height(700)}/filters:quality(90)`} 700w,` : ''}
                    ${imageWidth >= 650 ? `${file + `/m/650x${Height(650)}/filters:quality(90)`} 650w,` : ''}
                    ${imageWidth >= 600 ? `${file + `/m/600x${Height(600)}/filters:quality(90)`} 600w,` : ''}
                    ${imageWidth >= 500 ? `${file + `/m/500x${Height(500)}/filters:quality(90)`} 500w,` : ''}
                    ${imageWidth >= 400 ? `${file + `/m/400x${Height(400)}/filters:quality(90)`} 400w,` : ''}
                `}
                sizes={sizes ? sizes : "(min-width: 1410px) 1410px, 100vw"}
                src={file}
                width={file?.split('/')[5]?.split('x')[0] !== '0' ? file?.split('/')[5]?.split('x')[0] : '250'}
                height={square ? file?.split('/')[5]?.split('x')[0] : file?.split('/')[5]?.split('x')[1] !== '0' ? file?.split('/')[5]?.split('x')[1] : '250'}
                alt={img?.name || img?.alt || ''}
                loading={eager ? 'eager' : defaultLoading ? null : img?.source === 'eager' ? 'eager' : 'lazy'}
                fetchpriority={eager ? 'high' : null}
            />
        )
    } else {
        return null;
    }
}