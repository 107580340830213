import React, { useState } from 'react'
import { Link } from 'gatsby'

import Logo from './Logo'

import '../../static/styles/navbar.css'

export default () => {
  
  const [resourcesDropdownExpanded, setResourcesDropdownExpanded] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-white fixed-top"
      data-sticky="top"
      style={{ minHeight: '78px', top: '0px' }}
    >
      <div className="container">
        <Link
          title="QuickMail"
          className="navbar-brand d-flex align-items-center"
          to="/"
        >
          <Logo />
          <div className="brand-name">QuickMail</div>
        </Link>
        <button
          className={`navbar-toggler ${mobileMenuOpen ? '' : 'collapsed'}`}
          type="button"
          data-toggle="collapse"
          data-target="#navbar-dropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{
            border: 'none',
          }}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <svg
            className="icon"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z"
              fill="#212529"
            />
          </svg>
        </button>
        <div
          className={`${mobileMenuOpen ? '' : 'collapse'} navbar-collapse justify-content-between`}
          id="navbar-dropdown"
        >
          <ul className="navbar-nav nobreak">
            <li
              itemScope="itemscope"
              itemType="https://www.schema.org/SiteNavigationElement"
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <Link
                title="Why QuickMail"
                className="nav-link"
                activeClassName="active"
                to="/why-quickmail"
              >
                Why QuickMail
              </Link>
            </li>

            <li
              itemScope="itemscope"
              itemType="https://www.schema.org/SiteNavigationElement"
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <Link
                title="For Lead Gen Agencies"
                className="nav-link"
                activeClassName="active"
                to="/agencies"
              >
                For Lead Gen Agencies
              </Link>
            </li>

            <li
              itemScope="itemscope"
              itemType="https://www.schema.org/SiteNavigationElement"
              className="dropdown dropdown-grid nav-item"
            >
              <a
                title="Solutions"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={resourcesDropdownExpanded}
                className={`dropdown-toggle nav-link ${resourcesDropdownExpanded ? 'active' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement.classList.toggle('show');
                  e.target.nextElementSibling.classList.toggle('show');
                }}
              >
                Solutions
              </a>
              <div
                className="dropdown-menu row"
                id="resources-dropdown-menu"
                onMouseEnter={(e) => {
                  e.preventDefault()
                  setResourcesDropdownExpanded(true)
                }}
                onMouseLeave={(e) => {
                  e.preventDefault()
                  setResourcesDropdownExpanded(false)
                }}
              >
                <div
                  className="dropdown-menu-group col-auto p-0"
                  data-dropdown-content
                >
                  <ul className="dropdown-grid-menu list-unstyled">
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Deliverability AI"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/deliverability-ai"
                      >
                        Deliverability AI
                      </Link>
                    </li>
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Advanced Analytics"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/analytics"
                      >
                        Advanced Analytics
                      </Link>
                    </li>
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Scale Your Campaigns"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/inbox-rotation"
                      >
                        Scale Your Campaigns
                      </Link>
                    </li>
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Bring Your Entire Team "
                        className="dropdown-item"
                        activeClassName="active"
                        to="/unlimited-users"
                      >
                        Bring Your Entire Team
                      </Link>
                    </li>
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Add Inboxes Securely"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/add-inboxes-securely"
                      >
                        Add Inboxes Securely
                      </Link>
                    </li>
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Integrations"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/integrations"
                      >
                        Integrations
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li
              itemScope="itemscope"
              itemType="https://www.schema.org/SiteNavigationElement"
              className="nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <Link
                title="Pricing"
                className="nav-link"
                activeClassName="active"
                to="/pricing"
              >
                Pricing
              </Link>
            </li>

            <li
              itemScope="itemscope"
              itemType="https://www.schema.org/SiteNavigationElement"
              className="dropdown dropdown-grid nav-item"
            >
              <a
                title="Resources"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={resourcesDropdownExpanded}
                className={`dropdown-toggle nav-link ${resourcesDropdownExpanded ? 'active' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement.classList.toggle('show');
                  e.target.nextElementSibling.classList.toggle('show');
                }}
              >
                Resources
              </a>
              <div
                className="dropdown-menu row"
                id="resources-dropdown-menu"
                onMouseEnter={(e) => {
                  e.preventDefault()
                  setResourcesDropdownExpanded(true)
                }}
                onMouseLeave={(e) => {
                  e.preventDefault()
                  setResourcesDropdownExpanded(false)
                }}
              >
                <div
                  className="dropdown-menu-group col-auto p-0"
                  data-dropdown-content
                >
                  <ul className="dropdown-grid-menu list-unstyled">
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="The Cold Email Guide"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/cold-email"
                      >
                        The Cold Email Guide
                      </Link>
                    </li>
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Blog"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/blog"
                      >
                        Blog
                      </Link>
                    </li>
                    <li
                      itemScope="itemscope"
                      itemType="https://www.schema.org/SiteNavigationElement"
                      className="nav-item"
                    >
                      <Link
                        title="Book"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/newsletter"
                      >
                        Cold Outreach Advice
                      </Link>
                    </li>
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="CTA Swipe File"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/cta-swipe-file"
                      >
                        CTA Swipe File
                      </Link>
                    </li>
                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Courses"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/cold-email-mastery-courses"
                      >
                        Courses
                      </Link>
                    </li>

                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Customer Stories"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/podcast"
                      >
                        Podcast
                      </Link>
                    </li>

                    <li
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <Link
                        title="Customer Stories"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/testimonials"
                      >
                        Customer Stories
                      </Link>
                    </li>

                    <li
                      itemScope="itemscope"
                      itemType="https://www.schema.org/SiteNavigationElement"
                      className="nav-item"
                    >
                      <Link
                        title="Book"
                        className="dropdown-item"
                        activeClassName="active"
                        to="/book"
                      >
                        Book
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <div className="d-flex flex-column flex-lg-row align-items-center nobreak">
            <a
              title="Log In"
              href="https://next.quickmail.com/login"
              className="nav-link login-link link"
            >
              Log In
            </a>

            <a
              href="https://next.quickmail.com/signup"
              className="btn btn-primary purple-btn"
            >
              Start My Trial
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}
