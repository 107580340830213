import React, { useState } from 'react'
import Image from "utils/Image"

import '../../static/styles/hellobar.css'

export default ({ text, url, bg_color, text_color, profile }) => {
  const [resourcesDropdownExpanded, setResourcesDropdownExpanded] = useState(
    false
  )

  return (
    <a
      title="Find Out How"
      href={url}
      // target="_blank"
      rel="noreferrer"
      pointerEvents="none"
    >
      <div id="hellobar" style={{ backgroundColor: bg_color }}>
        <div className="hello-container">
          <div className="img">
            {profile && (
              <Image img={profile} alt="Webinar speaker" style={{ borderRadius: '50%' }} />
            )}
          </div>
          <div style={{ paddingLeft: '10px', paddingTop: '13px' }}>
            <p className="text-center" style={{ color: text_color }}>
              {text}
           </p>
          </div>
        </div>
      </div>
    </a>
  );
}
