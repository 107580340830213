import React, { useState } from 'react'
import { Link } from 'gatsby'

import Logo from './Logo'

import '../../static/styles/hellobar.css'

export default () => {
  const [resourcesDropdownExpanded, setResourcesDropdownExpanded] = useState(
    false
  )

  return <div id="emptybar"></div>
}
