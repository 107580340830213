import React from 'react'
import { Link } from 'gatsby'
import '../../static/styles/footer.css'

export default () => (
  <footer className="dark-blue-bg" id="footer">
    <div className="container content dark-blue-bg">
      <div className="row">
        <div className="col-12 brand-group">
          <div>
            <a className="brand" href="/">
              QuickMail
            </a>
          </div>
          <small className="text-muted">
            © Copyright 2014-{new Date().getFullYear()}. All Rights Reserved.
          </small>
        </div>
      </div>
    </div>
  </footer>
)
