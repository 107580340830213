exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-templates-author-detail-page-js": () => import("./../../../src/templates/author-detail-page.js" /* webpackChunkName: "component---src-templates-author-detail-page-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-competitor-page-js": () => import("./../../../src/templates/competitor-page.js" /* webpackChunkName: "component---src-templates-competitor-page-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-knowledge-hub-js": () => import("./../../../src/templates/knowledge-hub.js" /* webpackChunkName: "component---src-templates-knowledge-hub-js" */),
  "component---src-templates-knowledge-page-js": () => import("./../../../src/templates/knowledge-page.js" /* webpackChunkName: "component---src-templates-knowledge-page-js" */),
  "component---src-templates-knowledge-subpage-js": () => import("./../../../src/templates/knowledge-subpage.js" /* webpackChunkName: "component---src-templates-knowledge-subpage-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-modular-page-js": () => import("./../../../src/templates/modular-page.js" /* webpackChunkName: "component---src-templates-modular-page-js" */),
  "component---src-templates-plain-page-js": () => import("./../../../src/templates/plain-page.js" /* webpackChunkName: "component---src-templates-plain-page-js" */),
  "component---src-templates-testimonial-index-js": () => import("./../../../src/templates/testimonial-index.js" /* webpackChunkName: "component---src-templates-testimonial-index-js" */),
  "component---src-templates-testimonial-js": () => import("./../../../src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */)
}

