import React from 'react'
// import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { storyblokInit } from "gatsby-source-storyblok"

// Import typefaces
import '@fontsource/manrope'
import '@fontsource/manrope/500.css'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/700.css'
import '@fontsource/manrope/800.css'
import '@fontsource/open-sans'

import Hellobar from './Hellobar'
import Emptybar from './Emptybar'
import Navbar from './Navbar'
import MiniNavbar from './MiniNavbar'
import Footer from './Footer'
import MiniFooter from './MiniFooter'
// import CookieNotice from './CookieNotice'

// Initialize Storyblok live editor
storyblokInit({
  accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
  apiOptions: {
    region: "us",
  },
});

export default ({ children, location }) => {
  // useEffect(() => {
  //   if (window.Beacon && !document.getElementById('beacon-container')) {
  //     window.Beacon('config', { color: '#545ff5' })
  //     // if (location.pathname === '/pricing'
  //     window.Beacon('init', 'cfe2128a-3744-4e1e-b139-62c42f612cfa')
  //     // return () => window.Beacon('destroy')
  //   }
  // }, [])

  return (
    <StaticQuery
      query={graphql`query LayoutQuery {
  latestPosts: allStoryblokEntry(
    sort: { order: [DESC], fields: [published_at] }
    filter: {
      field_component: { eq: "post" }
      field_hidden_boolean: { ne: true }
    }
    limit: 2
  ) {
    nodes {
      content
      published_at(formatString: "MMMM D, YYYY")
      name
      slug
    }
  }
  settings: storyblokEntry(slug: { eq: "general" }) {
    content
  }
}
`}
      render={(data) => {
        const settings = JSON.parse(data.settings.content)
        const siteTitle = settings.site_title
        const siteDescription = settings.site_description

        const latestsPosts = data.latestPosts.nodes

        const url = location.href ? location.href : ''

        const isLandingPage =
          children.props.data && children.props.data.landingPage
        
        const isModularPage =
          children.props.data && children.props.data.modularPage

        const helloBar = settings.hellobar

        let mininav = true
        if (children.props.data && children.props.data.landingPage) {
          let landingPageContent;
          if (typeof children.props.data.landingPage.content === 'string') {
            landingPageContent = JSON.parse(children.props.data.landingPage.content)
          } else {
            landingPageContent = children.props.data.landingPage.content
          }
          mininav = JSON.parse(landingPageContent.use_mini_navbar)
        }

        // let rootPath = `/`
        // let postsPath = `/posts`
        // if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
        //   rootPath = __PATH_PREFIX__ + `/`
        //   postsPath = __PATH_PREFIX__ + `/posts`
        // }

        // if (location.pathname === rootPath || location.pathname === postsPath) {
        // } else {
        // }
        return (
          <>
            {!isLandingPage && helloBar && (
              <Hellobar
                text={helloBar.content?.text}
                url={helloBar.content?.url}
                bg_color={helloBar.content?.bg_color}
                text_color={helloBar.content?.text_color}
                profile={settings.profile}
              />
            )}
            {!isLandingPage && !helloBar && <Emptybar />}
            {!isLandingPage && <Navbar />}
            {isLandingPage && (mininav || mininav == undefined) && (
              <MiniNavbar />
            )}

            {isLandingPage && mininav == false && helloBar && (
              <Hellobar
                text={helloBar.content?.text}
                url={helloBar.content?.url}
                bg_color={helloBar.content?.bg_color}
                text_color={helloBar.content?.text_color}
                profile={settings.profile}
              />
            )}
            {isLandingPage && mininav == false && !helloBar && <Emptybar />}
            {isLandingPage && mininav == false && <Navbar />}
            <main
              className="main"
            >
              {children}
            </main>
            {/* {!isLandingPage && !isModularPage && <FooterBanner />} */}
            {/* {!isLandingPage && <Footer posts={latestsPosts} diagonalBefore={isModularPage} />} */}
            <Footer posts={latestsPosts} diagonalBefore={isModularPage} />
            {isLandingPage && <MiniFooter />}
            {/* <CookieNotice /> */}
          </>
        )
      }}
    />
  );
}
